/**
 * Array of suported locales
 */

export const supportedLocales = [
    "en",
    "fr",
    "en-US",
    "it",
    "zh-Hant",
    "es",
    "ko",
    "ar",
    "id",
    "fa",
    "vi",
    "pl",
    "ms",
    "de",
    "zh-Hans",
    "ja",
    "pt-BR",
    "ru",
    "tr",
    "th",
    "nl",
    "hi",
    "he",
];