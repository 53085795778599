import { withTranslation, useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/languageSwitcher/languageSwitcher";
import LogoWhite from "../../assets/icons/logoWhite.svg";
import "./maintenance.scss";

const Maintenance = ({ rightToLeft }) => {
  const { t } = useTranslation();
  const gatewayError = t("maintenance").split('.');

  return (
    <>
      <LanguageSwitcher rightToLeft={rightToLeft} />

      <div className="maintenance">
        <div className="imagesWrapper">
          <img src={LogoWhite} alt="logo" />
        </div>

        <div className="maintenanceContent">
          <div className="bigTitle">
            ROLEX WATCH ID
          </div>
          <div>
            {gatewayError &&
              gatewayError.map((item, index) => (
                <span className="smallTitle" key={index}>
                  {item.length ? item.trim() + "." : ''}
                </span>
              ))
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(Maintenance);
