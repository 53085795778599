import React, { useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { preferedLanguage } from "./preferedLanguage";
import Close from "../../assets/icons/close.svg";
import LanguageIcon from "../../assets/icons/language.svg";
import LogoColor from "../../assets/icons/logo-color.svg";
import "./languageSwitcher.scss";

const LanguageSwitcher = ({ rightToLeft }) => {
  const { t, i18n } = useTranslation();
  const [isLanguagesOpen, setLanguagesOpen] = useState(false);
  const handleClick = () => {
    window.location.reload();
  };

  const languagesArray = [
    { name: "English", code: "en" },
    { name: "Français", code: "fr" },
    { name: "English US", code: "en-US" },
    { name: "Deutsch", code: "de" },
    { name: "Italiano", code: "it" },
    { name: "简体中文", code: "zh-Hans" },
    { name: "繁體中文", code: "zh-Hant" },
    { name: "日本語", code: "ja" },
    { name: "Español", code: "es" },
    { name: "Português", code: "pt-BR" },
    { name: "한국어", code: "ko" },
    { name: "Русский", code: "ru" },
    { name: "عربي", code: "ar" },
    { name: "Türkçe", code: "tr" },
    { name: "Bahasa Indonesia", code: "id" },
    { name: "ภาษาไทย", code: "th" },
    { name: "فارسی", code: "fa" },
    { name: "Nederlands", code: "nl" },
    { name: "Tiếng Việt", code: "vi" },
    { name: "हिंदी", code: "hi" },
    { name: "Polski", code: "pl" },
    { name: "עברית", code: "he" },
    { name: "Bahasa Malaysia", code: "ms" },
  ];

  return (
    <>
      <img
        onClick={() => setLanguagesOpen(!isLanguagesOpen)}
        className={rightToLeft ? "langButtonRTL" : "langButton"}
        alt=""
        src={LanguageIcon}
      ></img>

      <div className={!isLanguagesOpen ? "overlay" : "overlayShown"}></div>

      <div
        id={rightToLeft ? "sliderRTL" : "slider"}
        className={isLanguagesOpen ? "slide-in" : ""}
      >
        <ul className="navigation">
          <img
            onClick={() => setLanguagesOpen(!isLanguagesOpen)}
            className="closeButton"
            alt=""
            src={Close}
          ></img>
          <img
            className="logoNav"
            src={LogoColor}
            alt="logo"
          />

          <li className="heading">{t("languages")}</li>

          <li>
            {languagesArray.map((language, index) => (
              <div
                className={
                  preferedLanguage() === language.code
                    ? "selectedLanguage"
                    : null
                }
                onClick={() =>
                  i18n.changeLanguage(language.code) & handleClick()
                }
                key={index}
              >
                {language.name}
              </div>
            ))}
          </li>
        </ul>
      </div>
    </>
  );
};

export default withTranslation()(LanguageSwitcher);
