import React from "react";
import Maintenance from "./pages/maintenance/maintenance";

function App() {
  const rightToLeft = localStorage.getItem("i18nextLng").match(/^(ar|he|fa)$/) && true;

  return (
    <div className={rightToLeft ? "rtl" : ""}>
      <Maintenance rightToLeft={rightToLeft} />
    </div>
  );
}

export default App;
